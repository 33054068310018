import { Tooltip } from 'antd'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { DROPDOWN_ACTION, DROPDOWN_ACTION_PERMISSION } from 'src/constants/grade'
import GradeIcon from '../GradeIcon'
import { useConfirm } from 'src/hooks/use-confirm'
import clsx from 'clsx'
import { GradingStatus } from 'src/type/grade'
import { useUserContext } from 'src/context/UserProvider'
import { hasPermission } from 'src/utils/permission'
import { TITLE_ATTEMPT_GRADING_MANAGEMENT_GR } from 'src/constants/permission'

interface IDropDownActionsProps {
  openGrid: boolean
  setOpenGrid: (openGrid: boolean) => void
  setOpenRecommendation: (openRecommendation: boolean) => void
  status: string
}

const DropDownActions = ({
  openGrid,
  setOpenGrid,
  setOpenRecommendation,
  status,
}: IDropDownActionsProps) => {
  const navigate = useNavigate()
  const { confirm, contextHolder } = useConfirm()
  const [openAction, setOpenAction] = useState<boolean>(false)
  const [canFinish, setCanFinish] = useState<boolean>(true)
  const actionRef = useRef<HTMLDivElement>(null)

  // Trigger finish grading action
  const onFinishAction = () => {
    window.dispatchEvent(new CustomEvent('onFinishGrading'))
  }

  // Handle click outside action dropdown
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (openAction && actionRef.current && !actionRef.current.contains(event.target as Node)) {
        setOpenAction(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [openAction])

  useEffect(() => {
    const afterFinishGrading = () => setCanFinish(false)

    window.addEventListener('onFinishedGrading', afterFinishGrading)

    return () => {
      window.removeEventListener('onFinishedGrading', afterFinishGrading)
    }
  }, [setCanFinish])

  const handleFinish = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn kết thúc không?'],
      onClick: () => {
        onFinishAction()
      },
    })
  }

  const handleExit = (url: string) => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn thoát không?'],
      onClick: () => {
        navigate(url)
      },
    })
  }

  const { profileMe } = useUserContext()

  const allowRenderAwaitingGrade = hasPermission(
    profileMe?.roles,
    TITLE_ATTEMPT_GRADING_MANAGEMENT_GR.ATTEMPT_GRADING
  )

  return (
    <>
      {contextHolder}
      <div className='d-flex grade-header-icon-wrapper'>
        {allowRenderAwaitingGrade && (
          <span
            className={clsx('grade-header-icon', { 'open-grid': openGrid })}
            onClick={() => setOpenGrid(!openGrid)}
          >
            <GradeIcon iconType='grid' />
          </span>
        )}
        <div className='icon-action' ref={actionRef}>
          <span
            className={clsx('grade-header-icon', { 'open-action': openAction })}
            onClick={() => setOpenAction(!openAction)}
          >
            <GradeIcon iconType='down' />
          </span>
          <ul className={clsx({ 'open-action-drop-down': openAction })}>
            {(allowRenderAwaitingGrade ? DROPDOWN_ACTION : DROPDOWN_ACTION_PERMISSION).map(
              ({ label, event, url, icon, description }) => (
                <li
                  className='align-items-center d-flex gap-1'
                  key={label}
                  onClick={() => {
                    setOpenAction(false)
                    event === 'exit' && url && handleExit(url)
                    event === 'finish' &&
                      canFinish &&
                      status !== GradingStatus.FINISHED &&
                      handleFinish()
                    if (event === 'openAction') setOpenRecommendation(true)
                  }}
                >
                  {label}
                  {icon && (
                    <>
                      {' '}
                      <Tooltip title={description} color='white'>
                        {' '}
                        <GradeIcon iconType={icon} />
                      </Tooltip>
                    </>
                  )}
                </li>
              )
            )}
          </ul>
        </div>
      </div>
    </>
  )
}

export default DropDownActions
