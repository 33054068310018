import { debounce } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { Control } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { EventAPI } from 'src/apis/events'
import SAPPFIlterButton from 'src/common/SAPPFIlterButton'
import { FILTER_SELECTALL_SORTBY, PageLink } from 'src/constants'
import { OPTIONS_OF_STATUS } from 'src/constants/entrancetest'
import { LANG_SIDEBAR } from 'src/constants/lang'
import { TITLE_EVENT_TEST_GR } from 'src/constants/permission'
import { useUserContext } from 'src/context/UserProvider'
import useInfinitePrograms from 'src/hooks/useInfiniteCourseCategory'
import { ISubject } from 'src/type/subject'
import { hasPermission } from 'src/utils/permission'
import HookFormSelectAntd from '../base/select/HookFormSelectAntd'
import HookFormTextField from '../base/textfield/HookFormTextField'
import ListFilterLayout from '../layout/listFilter'
import ButtonIconPrimary from '../ui/button-icon-primary/ButtonIconPrimary'

interface IProps {
  control: Control
  onSubmit: () => void
  handleReset: () => void
  setValue: any
  watch: any
  queryParams: any
  loading: boolean
}

const HeaderEventTest = ({
  control,
  onSubmit,
  handleReset,
  watch,
  setValue,
  queryParams,
  loading,
}: IProps) => {
  const [subjects, setSubjects] = useState<any>()
  const requestOngoingRef = useRef(false)
  const navigate = useNavigate()

  /**
   * @description scroll data goi API trong select
   */
  const handleNextPageSubject = async (params: Object) => {
    const totalPages = subjects?.meta?.total_pages
    const pageIndex = subjects?.meta?.page_index as number
    const pageSize = subjects?.meta?.page_size as number
    if (totalPages && pageIndex < totalPages) {
      if (requestOngoingRef.current) return
      requestOngoingRef.current = true
      const res = await fetchSubjects(pageIndex + 1, pageSize, params)
      if (res) {
        const results = subjects.events.concat(res.data.events)
        setSubjects({
          meta: res.data.meta,
          subjects: results,
        })
      }
      requestOngoingRef.current = false
    }
  }

  /**
   * @description function config API
   */
  const fetchSubjects = async (page_index: number, page_size: number, params: Object) => {
    try {
      const res = await EventAPI.get(page_index, page_size, params)
      return res
    } catch (error) {}
  }

  const getSubjects = async (params: Object) => {
    const res = await fetchSubjects(1, 20, params)
    setSubjects(res?.data)
  }

  /**
   * @description sau 0.5s mới call API
   */
  const debounceSearchSubject = debounce((e) => {
    getSubjects({
      params: {
        name: e,
        ...(watch('course_category_id') && { course_category_id: watch('course_category_id') }),
      },
    })
  }, 500)

  const subjectCourse = subjects?.events?.map((subject: ISubject) => ({
    label: subject.name,
    value: subject.id,
  }))

  // Xử lý lấy subject theo course category id vừa chọn
  const handleCourseCategoryChange = async () => {
    setSubjects(undefined)
    setValue('event_id', '')
    getSubjects({
      params: {
        ...(watch('course_category_id') && { course_category_id: watch('course_category_id') }),
      },
    })
  }

  useEffect(() => {
    const fetchCourseCategory = async () => {
      try {
        if (watch('course_category_id')) {
          await getSubjects({ course_category_ids: watch('course_category_id') })
        }
      } catch (error) {}
    }
    fetchCourseCategory()
  }, [])

  const { profileMe } = useUserContext()
  const allowRenderCreateEventTest = hasPermission(
    profileMe?.roles,
    TITLE_EVENT_TEST_GR.CREATE_EVENT_TEST
  )

  const {
    programs,
    refetch: refetchPrograms,
    hasNextPage: hasNextPageProgram,
    fetchNextPage: fetchNextPageProgram,
    debounceSearch,
  } = useInfinitePrograms()

  return (
    <>
      <div className='px-9 border-0 pt-6'>
        {/* begin:: Search */}

        <ListFilterLayout>
          <HookFormTextField
            control={control}
            name='name'
            placeholder='Search'
            defaultValue={queryParams?.name}
            onSubmit={onSubmit}
            isListScreen
          />

          <HookFormSelectAntd
            control={control}
            name='course_category_id'
            placeholder='Program'
            defaultValue={queryParams.course_category_id ?? ''}
            onSearch={(text) => {
              debounceSearch(text)
            }}
            showSearch
            onChange={handleCourseCategoryChange}
            classNameHeight='sapp-h-40'
            handleNextPage={hasNextPageProgram && fetchNextPageProgram}
            onDropdownVisibleChange={(open) => {
              if (open && !programs) {
                refetchPrograms()
                return
              }
            }}
            options={programs?.map((program) => ({ label: program.name, value: program.id })) ?? []}
          />

          <HookFormSelectAntd
            control={control}
            name='event_id'
            placeholder='Event'
            onSearch={(e: any) => {
              if (e === undefined) {
                return
              }
              debounceSearchSubject(e)
            }}
            handleNextPage={(e: any) =>
              handleNextPageSubject({
                name: e,
                course_category_id: watch('course_category_id'),
              })
            }
            showSearch
            classNameHeight='sapp-h-40'
            // loading={loading}
            defaultValue={queryParams?.event_id ?? ''}
            options={subjectCourse ?? []}
          />

          <HookFormSelectAntd
            name='sortType'
            placeholder='Sort by'
            control={control}
            defaultValue={queryParams?.sortType ?? ''}
            classNameHeight='sapp-h-40'
            options={FILTER_SELECTALL_SORTBY}
          />

          <HookFormSelectAntd
            name='status'
            placeholder='Status'
            control={control}
            defaultValue={queryParams?.status ?? ''}
            classNameHeight='sapp-h-40'
            options={OPTIONS_OF_STATUS}
          />
        </ListFilterLayout>
      </div>
      <div className='card-header border-0 pt-6 px-9'>
        <div className='d-flex'>
          <SAPPFIlterButton
            titleReset='Reset'
            titleSubmit='Search'
            okClick={onSubmit}
            resetClick={handleReset}
            disabled={loading}
            loading={loading}
          />
        </div>
        {allowRenderCreateEventTest && (
          <ButtonIconPrimary
            iconName='plus'
            title={LANG_SIDEBAR.createEvent}
            onClick={() => navigate(`${PageLink.CREATE_EVENT_TEST}/undefined`)}
            size='small'
          />
        )}
      </div>
    </>
  )
}

export default HeaderEventTest
