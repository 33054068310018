import { zodResolver } from '@hookform/resolvers/zod'
import { isUndefined } from 'lodash'
import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useNavigate, useParams } from 'react-router-dom'
import ClassroomApi from 'src/apis/classroom'
import HeaderTab from 'src/components/base/HeaderTab'
import DistrictSelect from 'src/components/base/select/common/DistrictSelect'
import ProvinceSelect from 'src/components/base/select/common/ProvinceSelect'
import WardSelect from 'src/components/base/select/common/WardSelect'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import WarningText from 'src/components/base/WarningText'
import ButtonPrimary from 'src/components/ui/button-primary/ButtonPrimary'
import ButtonSecondary from 'src/components/ui/button-secondary/ButtonSecondary'
import { FACILITY_STATUS, PageLink, VALIDATION_FIELD } from 'src/constants'
import { TITLE_OPTIONS_AREA } from 'src/constants/classroom'
import { useConfirm } from 'src/hooks/use-confirm'
import { IFacility } from 'src/type/area'
import { z } from 'zod'

interface IProps {
  loading: boolean
  areaDetail: IFacility | undefined
  refetch: () => void
}

interface IInputProps {
  name: string
  code: string
  province_code: string
  district_code: string
  ward_code: string
  address?: string
  status?: string
}

const AreaProfileSetting = ({ areaDetail, refetch }: IProps) => {
  const { id } = useParams()
  const { contextHolder } = useConfirm()
  const navigate = useNavigate()
  const isCheckEnded = areaDetail?.status === 'ENDED' ? true : false
  const [isLoading, setIsLoading] = useState<boolean>()

  const validationSchema = z.object({
    name: z.string({ required_error: VALIDATION_FIELD }).min(1, { message: VALIDATION_FIELD }),
    code: z.string({ required_error: VALIDATION_FIELD }).min(1, { message: VALIDATION_FIELD }),
    province_code: z
      .string({ required_error: VALIDATION_FIELD })
      .min(1, { message: VALIDATION_FIELD }),
    district_code: z
      .string({ required_error: VALIDATION_FIELD })
      .min(1, { message: VALIDATION_FIELD }),
    ward_code: z.string({ required_error: VALIDATION_FIELD }).min(1, { message: VALIDATION_FIELD }),
    address: z.string().min(1, { message: VALIDATION_FIELD }),
    status: z.string({ required_error: VALIDATION_FIELD }).min(1, { message: VALIDATION_FIELD }),
  })

  const { handleSubmit, control, setValue, watch } = useForm<IInputProps>({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
    defaultValues: {
      name: '',
      code: '',
      province_code: '',
      district_code: '',
      ward_code: '',
      address: '',
      status: '',
    },
  })

  const initData = async () => {
    if (id && !isUndefined(areaDetail)) {
      setValue('name', areaDetail?.name ?? '')
      setValue('code', areaDetail?.code ?? '')
      setValue('address', areaDetail?.address ?? '')
      setValue('province_code', areaDetail?.ward?.district?.province?.code as string)
      setValue('district_code', areaDetail?.ward?.district?.code ?? '')
      setValue('ward_code', areaDetail?.ward?.code ?? '')
      setValue('status', areaDetail?.status ?? '')
    }
  }

  const onSubmit: SubmitHandler<IInputProps> = async (data: IInputProps) => {
    try {
      setIsLoading(true)
      if (!id) return
      const res = await ClassroomApi.editAreaClass(id, data)
      if (res?.success) {
        refetch()
      }
      toast.success('Update Successfully!')
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    initData()
  }, [areaDetail, id])

  return (
    <>
      {contextHolder}
      <div className='card mb-5 mb-xl-10'>
        <HeaderTab title={TITLE_OPTIONS_AREA.editFacility} />
        <div className='collapse show'>
          <div className='card-body card-body px-10 pt-8 pb-4 row'>
            <div className='mb-8 col-12'>
              <HookFormTextField
                required
                control={control}
                name='name'
                placeholder='Name'
                disabled={isCheckEnded}
                label='Name'
              />
            </div>
            <div className='mb-8 col-12'>
              <HookFormTextField
                required
                control={control}
                name='code'
                placeholder='Code'
                disabled={!!id}
                label='Code'
              />
            </div>
            <div className='mb-8 col-12 row'>
              <div className='col-lg-4 col-12'>
                <ProvinceSelect
                  control={control}
                  name='province_code'
                  onChange={(value) => {
                    if (!value) {
                      setValue('province_code', '')
                    }
                    setValue('district_code', '')
                    setValue('ward_code', '')
                  }}
                  label='Province'
                  required
                  detailData={{
                    label: areaDetail?.ward?.district?.province?.name ?? '',
                    value: areaDetail?.ward?.district?.province?.code ?? '',
                  }}
                />
              </div>
              <div className='col-lg-4 col-12'>
                <DistrictSelect
                  control={control}
                  name='district_code'
                  onChange={() => {
                    setValue('ward_code', '')
                  }}
                  label='District'
                  required
                  province_code={watch('province_code')}
                  detailData={{
                    label: areaDetail?.ward?.district.name ?? '',
                    value: areaDetail?.ward?.district?.code ?? '',
                  }}
                />
              </div>
              <div className='col-lg-4 col-12'>
                <WardSelect
                  control={control}
                  name='ward_code'
                  label='Ward'
                  required
                  district_code={watch('district_code')}
                  detailData={{
                    label: areaDetail?.ward?.name ?? '',
                    value: areaDetail?.ward?.code ?? '',
                  }}
                />
              </div>
            </div>
            <div className='mb-8 col-12'>
              {!!id && (
                <HookFormSelectAntd
                  control={control}
                  name='status'
                  placeholder='Status'
                  showSearch
                  label='Status'
                  required
                  options={FACILITY_STATUS}
                />
              )}
            </div>
            <div className='col-12'>
              <WarningText className='mb-8 mt-0' title='Địa chỉ cơ sở'>
                <ul>
                  <li className='sapp-content-alert mt-2'>Chỉ nhập số nhà, tên đường</li>
                </ul>
              </WarningText>
            </div>
            <div className='col-12 mb-8'>
              <HookFormTextField
                control={control}
                name='address'
                label='Address Detail'
                placeholder='Address Detail'
                required
              />
            </div>
          </div>
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <ButtonSecondary
              type='button'
              title='Cancel'
              className='me-5'
              onClick={() => navigate(`${PageLink.CLASSROOM_AREA_DETAIL}/${id}/overview`)}
              loading={false}
            />
            <ButtonPrimary
              type='submit'
              title='Save'
              loading={isLoading}
              onClick={handleSubmit(onSubmit)}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default AreaProfileSetting
