import { Col, Row, Select, Space } from 'antd'
import { uniqBy } from 'lodash'
import { Fragment, ReactNode, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { UsersAPI } from 'src/apis/user'
import SAPPDialogButtonsCancelSubmit from 'src/common/SAPPDialogButtonsCancelSubmit'
import HeaderTab from 'src/components/base/HeaderTab'
import SappLabel from 'src/components/base/label/SappLabel'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import { EntranceLevelSelect, PROGRAM, STUDENT_PROFILE } from 'src/constants'
import { HUBSPOT_ACCA_RESULT, HUBSPOT_CFA_RESULT, HUBSPOT_CMA_RESULT } from 'src/constants/hubspot'
import { userKeys } from 'src/constants/queryKeyFactory'
import { useStudentContext } from 'src/context/StudentContext'
import useInfiniteExamSubjects from 'src/hooks/useInfiniteExamSubjects'
import { IHubspotSubjects, IStudentDetail } from 'src/type/students'
import { ICourseTabReq } from 'src/type/users'
const { Option } = Select

interface EditRowProps {
  label: string // The label could be a string or any ReactNode
  body: string | ReactNode // The body can also be any ReactNode
}

interface IStudentProgramEdit {
  hubspot_account_info: string
  user_hubspot_examination_subjects: {
    examination_subject_id: string
    result: string
  }[]
  level?: string | null
}

interface Option {
  id: string
  name: string
}

const EditRow = ({ label, body }: EditRowProps) => (
  <Row gutter={24}>
    <Col span={7} className='d-flex align-items-center'>
      <SappLabel label={label} />
    </Col>
    <Col span={17}>{body}</Col>
  </Row>
)

const resultMap = {
  [PROGRAM.CFA]: HUBSPOT_CFA_RESULT,
  [PROGRAM.ACCA]: HUBSPOT_ACCA_RESULT,
  [PROGRAM.CMA]: HUBSPOT_CMA_RESULT,
}

const StudentProgramEditForm = ({
  program,
  hubspotSubjects,
}: {
  program: PROGRAM
  hubspotSubjects?: IHubspotSubjects
}) => {
  const queryClient = useQueryClient()
  const { id } = useParams<{ id: string }>()
  const { studentDetail: studentData } = useStudentContext()
  const navigate = useNavigate()

  const [subjectId, setSubjectId] = useState('')

  const { examSubjects, isLoading, hasNextPage, fetchNextPage } = useInfiniteExamSubjects(subjectId)

  const getDefault = (studentData?: IStudentDetail) => {
    const courseTabGroup = studentData?.course_tab_groups[program]
    const alignedSubjects = hubspotSubjects?.subjects.map((subject) => {
      return (
        courseTabGroup?.user_hubspot_examination_subjects?.find(
          (item) => item.examination_subject.subject_id === subject.id
        ) || null
      ) // Ensure indices are aligned, fill unmatched with null
    })
    return alignedSubjects
  }
  const [defaultSubjectValues, setDefaultSubjectValues] = useState(getDefault(studentData))
  const courseLevel = {
    [PROGRAM.CFA]: {
      defaultValue: studentData?.detail?.level,
      allowEdit: !studentData?.allow_edit_level,
    },
    [PROGRAM.CMA]: {
      defaultValue: studentData?.detail?.cma_level,
      allowEdit: !studentData?.allow_edit_cma_level,
    },
    [PROGRAM.ACCA]: {
      defaultValue: studentData?.detail?.acca_level,
      allowEdit: !studentData?.allow_edit_acca_level,
    },
  }

  const { control, handleSubmit, watch, setValue } = useForm<IStudentProgramEdit>({
    values: {
      hubspot_account_info:
        studentData?.user_hubspot_program_infos?.find(
          (info) => info.course_category.name.toUpperCase() === program
        )?.hubspot_account_info || null,
      user_hubspot_examination_subjects: (defaultSubjectValues || []).map((item) => ({
        examination_subject_id: item?.examination_subject.id || '',
        result: item?.result || '',
      })),
      level: courseLevel[program].defaultValue,
    },
  })

  const { mutate, isLoading: isEditting } = useMutation({
    mutationFn: (data: ICourseTabReq) => UsersAPI.editStudentProfileProgram(id!, data),
    onSuccess: (res) => {
      if (res.success) {
        toast.success(`Edit ${program} information successfully`)
        queryClient.invalidateQueries(userKeys.studentDetail(id!))
        navigate(`${STUDENT_PROFILE}/${id}/overview/${program.toLowerCase()}`)
      }
    },
  })

  const onSubmit: SubmitHandler<IStudentProgramEdit> = ({
    user_hubspot_examination_subjects,
    hubspot_account_info,
    level,
  }) => {
    mutate({
      course_category_id: hubspotSubjects?.subjects?.[0]?.course_category_id || null,
      hubspot_account_info: hubspot_account_info,
      user_hubspot_examination_subjects: user_hubspot_examination_subjects.filter(
        (item) => item.examination_subject_id || item.result
      ),
      level: level || null,
    })
  }

  useEffect(() => {
    setDefaultSubjectValues(getDefault(studentData))
  }, [hubspotSubjects, program])

  return (
    <div className='p-10'>
      <div className='card'>
        {' '}
        <HeaderTab title={`Edit ${program} information`} />
        <Space direction='vertical' size={'large'} className='p-10'>
          <EditRow
            label='Account Id'
            body={<HookFormTextField control={control} name='hubspot_account_info' />}
          />
          <EditRow
            label={`${program} Level`}
            body={
              <HookFormSelectAntd
                control={control}
                name={'level'}
                classNameHeight='sapp-h-45px'
                isLocked={courseLevel[program].allowEdit}
                disabled={courseLevel[program].allowEdit}
                options={EntranceLevelSelect}
              />
            }
          />

          {hubspotSubjects?.subjects.map((subject, index) => (
            <Fragment key={index}>
              <EditRow
                label={`${subject.name} Exam`}
                body={
                  <HookFormSelectAntd
                    control={control}
                    name={`user_hubspot_examination_subjects.${index}.examination_subject_id`}
                    placeholder='Select Exam'
                    showSearch
                    classNameHeight='sapp-h-40'
                    handleNextPage={() => {
                      hasNextPage && fetchNextPage()
                    }}
                    loading={isLoading}
                    onDropdownVisibleChange={(open) => {
                      open && setSubjectId(subject.id)
                    }}
                    onChange={(value: string) => {
                      setValue(
                        `user_hubspot_examination_subjects.${index}.examination_subject_id`,
                        value
                      ) // Set the name field
                      if (!value) {
                        // Clear password when name is cleared
                        setValue(`user_hubspot_examination_subjects.${index}.result`, '')
                      }
                    }}
                    options={uniqBy(
                      [
                        ...(defaultSubjectValues?.reduce<Option[]>((acc, item) => {
                          if (subject.id === item?.examination_subject.subject_id) {
                            acc.push({
                              id: item?.examination_subject.id,
                              name: item?.examination_subject.examination.name,
                            })
                          }
                          return acc
                        }, []) || []),
                        ...(examSubjects?.map((subject) => ({
                          id: subject.id,
                          name: subject.examination.name,
                        })) || []),
                      ],
                      'id'
                    ).map((mergedSubject) => ({
                      label: mergedSubject.name,
                      value: mergedSubject.id,
                    }))}
                  />
                }
              />
              <EditRow
                label={`${subject.name} Result`}
                body={
                  <HookFormSelectAntd
                    control={control}
                    name={`user_hubspot_examination_subjects.${index}.result`}
                    placeholder='Select Result'
                    defaultValue={''}
                    disabled={
                      !watch(`user_hubspot_examination_subjects.${index}.examination_subject_id`)
                    }
                    options={Object.values(resultMap[program] || {}).map((subject) => ({
                      label: subject,
                      value: subject,
                    }))}
                  />
                }
              />
            </Fragment>
          ))}
        </Space>
        <div className='card-footer'>
          <SAPPDialogButtonsCancelSubmit
            okButtonCaption='Save changes'
            cancelButtonCaption='Discard'
            okOnClick={handleSubmit(onSubmit)}
            cancelClick={() =>
              navigate(`${STUDENT_PROFILE}/${id}/overview/${program.toLowerCase()}`)
            }
            loading={isEditting}
          />
        </div>
      </div>
    </div>
  )
}

export default StudentProgramEditForm
