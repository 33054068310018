import { Select, Space } from 'antd'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import HeaderTab from 'src/components/base/HeaderTab'
import ItemProfile from 'src/components/base/ItemProfile'
import SAPPBadge from 'src/components/base/SAPPBadge'
import Text from 'src/components/base/text'
import { EntranceLevel, PROGRAM, STUDENT_PROFILE } from 'src/constants'
import { hubspotProgramResult, HubspotProgramResult } from 'src/constants/hubspot'
import { useStudentContext } from 'src/context/StudentContext'
import { CourseNameLevel } from 'src/type/courses'
import { IHubspotSubjects, IUserHubspotDeals } from 'src/type/students'
import styles from '../styles.module.scss'

const StudentProgramOverview = ({
  program,
  hubspotSubjects,
}: {
  program: PROGRAM
  hubspotSubjects?: IHubspotSubjects
}) => {
  const { id } = useParams()
  const { studentDetail } = useStudentContext()
  const userHubspotProgramInfo = studentDetail?.user_hubspot_program_infos?.find(
    (hubspotProgram) => hubspotProgram.course_category.name === program
  )
  const programInfos = studentDetail?.course_tab_groups[program]?.user_hubspot_examination_subjects
  const deals = studentDetail?.user_hubspot_deals.filter(
    (deal) => deal.course_category_id === hubspotSubjects?.course_category_id
  )
  const [selectedDeal, setSelectedDeal] = useState<IUserHubspotDeals | undefined>(
    deals?.[0] || undefined
  )

  const level = {
    [PROGRAM.CFA]: {
      levelLabel: CourseNameLevel.LEVEL,
      levelValue: EntranceLevel[studentDetail?.detail?.level ?? 'EMPTY'],
      currentLifeCycleValue: studentDetail?.current_cfa_lifecycle_stage,
    },
    [PROGRAM.CMA]: {
      levelLabel: CourseNameLevel.CMA,
      levelValue: EntranceLevel[studentDetail?.detail?.cma_level ?? 'EMPTY'],
      currentLifeCycleValue: studentDetail?.current_cma_lifecycle_stage,
    },
    [PROGRAM.ACCA]: {
      levelLabel: CourseNameLevel.ACCA,
      levelValue: EntranceLevel[studentDetail?.detail?.acca_level ?? 'EMPTY'],
      currentLifeCycleValue: studentDetail?.current_acca_lifecycle_stage,
    },
  }

  const getLabelColor = (result?: HubspotProgramResult) => {
    switch (result) {
      case hubspotProgramResult['Pass']:
      case hubspotProgramResult['Pass (Top 10%)']:
        return 'light-green'
      case hubspotProgramResult.Fail:
        return 'light-danger'
      case hubspotProgramResult['Chờ thi']:
        return 'light-primary'
      case hubspotProgramResult['Hoãn thi']:
        return 'light-success'
      default:
        return 'light-dark'
    }
  }

  const handleChange = (value: string) => {
    const selected = deals?.find((item) => item?.id === value) || undefined
    setSelectedDeal(selected)
  }

  useEffect(() => {
    const deals = studentDetail?.user_hubspot_deals.filter(
      (deal) => deal.course_category_id === hubspotSubjects?.course_category_id
    )
    setSelectedDeal(deals?.[0])
  }, [program])

  return (
    <Space className='p-10' direction='vertical' size={'large'}>
      <div className='card'>
        <HeaderTab
          title={`${program} Information`}
          titleButton={'Edit'}
          link={`${STUDENT_PROFILE}/${id}/setting/${program.toLowerCase()}/edit`}
        />
        <div className='card-body'>
          <ItemProfile
            title='Account ID'
            align='align-self-center'
            body={<Text className='text-blue'>{userHubspotProgramInfo?.hubspot_account_info}</Text>}
          />

          <ItemProfile title='Level' body={<Text>{level[program].levelValue}</Text>} />
          <ItemProfile
            title={`Current life cycle stage`}
            body={<Text>{level[program].currentLifeCycleValue}</Text>}
          />
          {hubspotSubjects?.subjects?.map((item) => {
            const program = programInfos?.find(
              (program) => program?.examination_subject?.subject_id === item?.id
            )

            return (
              <ItemProfile
                key={item?.id}
                align='align-self-center'
                title={`${item?.name} Exam`}
                body={
                  program?.examination_subject_id ? (
                    <Space size='middle' align='center'>
                      <p className='m-0'>
                        {program?.examination_subject?.examination?.name || '-'}
                      </p>
                      <SAPPBadge
                        className={getLabelColor(program?.result)}
                        label={program?.result}
                      />
                    </Space>
                  ) : (
                    <span className='text-gray-1000'>{'Chưa có'}</span>
                  )
                }
              />
            )
          })}
        </div>
      </div>
      <div className='card'>
        <div className={`card-header align-items-center`}>
          <div className='card-title m-0'>
            <h3 className='fw-bold m-0'>Deals list</h3>
          </div>
          <Select
            onChange={handleChange}
            defaultValue={deals?.[0]?.id}
            value={selectedDeal?.id}
            disabled={deals?.length === 0}
            options={deals?.map((item, index) => ({
              label: `Deal ${index + 1}`,
              value: item?.id,
            }))}
            placeholder='Select a deal'
          />
        </div>

        <div className='card-body'>
          {selectedDeal ? (
            <>
              <ItemProfile
                title='ID'
                body={<Text className='text-blue'>{selectedDeal?.hubspot_deal_id}</Text>}
              />
              <ItemProfile
                title='Learning purpose'
                body={<Text>{selectedDeal?.learning_purpose}</Text>}
              />
              <ItemProfile
                title='Convenient contact'
                body={<Text className='text-blue'>{selectedDeal?.convenient_contact}</Text>}
              />
              <ItemProfile
                title={`Important notes`}
                body={<Text className='w-50'>{selectedDeal?.important_note}</Text>}
              />
            </>
          ) : (
            <div className={styles.empty}>No deal selected</div>
          )}
        </div>
      </div>
    </Space>
  )
}

export default StudentProgramOverview
