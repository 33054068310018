import { DefaultOptionType } from 'antd/es/select'
import React, { useState } from 'react'
import { TITLE_OPTIONS_AREA } from 'src/constants/classroom'
import { getSelectOptions } from 'src/helper/getSelectOptions'
import useInfiniteProvinces from 'src/hooks/useInfiniteProvince'
import HookFormSelectAntd from '../HookFormSelectAntd'

interface ProvinceSelectProps {
  control: any
  name: string
  placeholder?: string
  defaultValue?: string
  classNameHeight?: 'sapp-h-45px' | 'sapp-h-35px' | 'sapp-h-40'
  allowClear?: boolean
  required?: boolean
  disabled?: boolean
  label?: string
  validateRequired?: boolean
  detailData?: { value: string; label: string }
  onChange?:
    | ((
        value: string | number,
        option?: DefaultOptionType | DefaultOptionType[] | undefined
      ) => void)
    | undefined
}

const ProvinceSelect: React.FC<ProvinceSelectProps> = ({
  control,
  name,
  placeholder = TITLE_OPTIONS_AREA.province,
  defaultValue = '',
  classNameHeight = 'sapp-h-40',
  allowClear = false,
  required = false,
  disabled = false,
  label,
  validateRequired = false,
  detailData,
  onChange,
}) => {
  const [dropdownVisible, setDropdownVisible] = useState(false)
  const {
    provinces,
    hasNextPage,
    fetchNextPage,
    isLoading: isProvincesLoading,
    refetch: refetchProvinces,
    debounceSearch: searchProvinces,
  } = useInfiniteProvinces(dropdownVisible)

  const baseOptions =
    provinces?.map((province) => ({
      label: province?.name,
      value: province?.code,
    })) ?? []

  const options = detailData ? getSelectOptions(baseOptions, detailData) : baseOptions

  return (
    <HookFormSelectAntd
      control={control}
      name={name}
      placeholder={placeholder}
      defaultValue={defaultValue}
      classNameHeight={classNameHeight}
      allowClear={allowClear}
      required={validateRequired || required}
      disabled={disabled}
      label={label}
      filterOption={false}
      loading={isProvincesLoading}
      showSearch
      onSearch={searchProvinces}
      onDropdownVisibleChange={(open) => {
        setDropdownVisible(open)
        if (open && !provinces?.length) {
          refetchProvinces()
        }
      }}
      handleNextPage={() => {
        hasNextPage && fetchNextPage()
      }}
      options={options}
      onChange={onChange}
    />
  )
}

export default ProvinceSelect
