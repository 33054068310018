import React, { useState } from 'react'
import { Control } from 'react-hook-form'
import { getSelectOptions } from 'src/helper/getSelectOptions'
import useMajors from 'src/hooks/useMajors'
import HookFormSelectAntd from '../HookFormSelectAntd'

interface MajorSelectProps {
  control: Control<any>
  disabled?: boolean
  selectedMajor?: { value?: string; label?: string }
}
const MajorSelect: React.FC<MajorSelectProps> = ({ control, disabled, selectedMajor }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false)

  // Example usage for multiple hooks
  const { majors, isLoading } = useMajors(dropdownVisible)
  const baseOptions =
    majors?.map((major) => ({
      label: major.name,
      value: major.id,
    })) ?? []

  const options = selectedMajor ? getSelectOptions(baseOptions, selectedMajor) : baseOptions
  return (
    <HookFormSelectAntd
      classNameHeight='sapp-h-45px'
      name='major_id'
      control={control}
      placeholder='Please select'
      disabled={disabled}
      loading={isLoading}
      onDropdownVisibleChange={(enabledVisible) => setDropdownVisible(enabledVisible)}
      options={options}
    />
  )
}

export default MajorSelect
