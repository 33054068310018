import { zodResolver } from '@hookform/resolvers/zod'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import ClassroomApi from 'src/apis/classroom'
import DistrictSelect from 'src/components/base/select/common/DistrictSelect'
import ProvinceSelect from 'src/components/base/select/common/ProvinceSelect'
import WardSelect from 'src/components/base/select/common/WardSelect'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import WarningText from 'src/components/base/WarningText'
import CreateEditLayout from 'src/components/layout/fullscreen/CreateEditLayout'
import ButtonPrimary from 'src/components/ui/button-primary/ButtonPrimary'
import { PageLink, VALIDATION_EMPTY_FIELD, VALIDATION_FIELD } from 'src/constants'
import { TITLE_OPTIONS_AREA } from 'src/constants/classroom'
import { DESCRIPTION_POPUPCONFIRM } from 'src/constants/lang'
import { useConfirm } from 'src/hooks/use-confirm'
import { IErrorFormArea } from 'src/type'
import { z } from 'zod'

interface IForm {
  name: string
  code: string
  province_code: string
  district_code: string
  ward_code: string
  address: string
  status: string
}

const NewArea = () => {
  const navigate = useNavigate()
  const { confirm, contextHolder } = useConfirm()
  const [loading, setLoading] = useState<boolean>(false)

  const validationSchema = z.object({
    name: z
      .string({ required_error: VALIDATION_FIELD })
      .min(1, { message: VALIDATION_EMPTY_FIELD }),
    code: z
      .string({ required_error: VALIDATION_FIELD })
      .min(1, { message: VALIDATION_EMPTY_FIELD }),
    province_code: z
      .string({ required_error: VALIDATION_FIELD })
      .min(1, { message: VALIDATION_EMPTY_FIELD }),
    district_code: z
      .string({ required_error: VALIDATION_FIELD })
      .min(1, { message: VALIDATION_EMPTY_FIELD }),
    ward_code: z
      .string({ required_error: VALIDATION_FIELD })
      .min(1, { message: VALIDATION_EMPTY_FIELD }),
    address: z
      .string({ required_error: VALIDATION_FIELD })
      .min(1, { message: VALIDATION_EMPTY_FIELD }),
  })

  const { handleSubmit, getValues, control, setValue, setError, watch } = useForm<IForm>({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
    defaultValues: {
      name: '',
      code: '',
      province_code: '',
      district_code: '',
      ward_code: '',
      address: '',
    },
  })

  const onSubmit = async (data: IForm) => {
    setLoading(true)
    try {
      await ClassroomApi.createAreaClass(data)
      navigate(PageLink.CLASSROOM_AREA)
      toast.success('Create successfully!')
    } catch (error: any) {
      error?.response?.data?.error?.others?.forEach((e: IErrorFormArea) => {
        const errorMessage = e?.errors?.[0]?.message
        setError(e.property, { message: errorMessage })
      }, {})
    } finally {
      setLoading(false)
    }
  }

  const handleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: [DESCRIPTION_POPUPCONFIRM],
      onClick: () => navigate(PageLink.CLASSROOM_AREA),
    })
  }

  return (
    <CreateEditLayout
      customPrimaryButton={
        <ButtonPrimary
          onClick={handleSubmit(onSubmit)}
          type='submit'
          title='Save'
          loading={loading}
          size='small'
        />
      }
      onCancel={() => handleCancel()}
      loading={loading}
      pageTitle={TITLE_OPTIONS_AREA.createFacility}
    >
      {contextHolder}
      <div className='px-10 pt-8 pb-4 row'>
        <div className='mb-8 col-12'>
          <HookFormTextField
            required
            control={control}
            name='name'
            placeholder='Name'
            label='Name'
          />
        </div>
        <div className='mb-8 col-12'>
          <HookFormTextField
            required
            control={control}
            name='code'
            placeholder='Code'
            label='Code'
          />
        </div>
        <div className='mb-8 col-12 row'>
          <div className='col-lg-4 col-12'>
            <ProvinceSelect
              control={control}
              name='province_code'
              onChange={(value) => {
                if (!value) {
                  setValue('province_code', '')
                }
                setValue('district_code', '')
                setValue('ward_code', '')
              }}
              label='Province'
              required
            />
          </div>
          <div className='col-lg-4 col-12'>
            <DistrictSelect
              control={control}
              name='district_code'
              onChange={() => {
                setValue('ward_code', '')
              }}
              label='District'
              required
              province_code={watch('province_code')}
            />
          </div>
          <div className='col-lg-4 col-12'>
            <WardSelect
              control={control}
              name='ward_code'
              label='District'
              required
              district_code={watch('district_code')}
            />
          </div>
        </div>
        <div className='col-12'>
          <WarningText className='mb-8 mt-0' title='Địa chỉ cơ sở'>
            <ul>
              <li className='sapp-content-alert mt-2'>Chỉ nhập số nhà, tên đường</li>
            </ul>
          </WarningText>
        </div>
        <div className='col-12 mb-8'>
          <HookFormTextField
            control={control}
            name='address'
            label='Address Detail'
            placeholder='Address Detail'
            required
          />
        </div>
      </div>
    </CreateEditLayout>
  )
}

export default NewArea
