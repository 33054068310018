import { useQuery } from 'react-query'
import { MajorApi } from 'src/apis/major'
import { majorKeys } from 'src/constants/queryKeyFactory'

const useMajors = (enabled: boolean) => {
  const {
    data: majors,
    isLoading,
    isFetching,
    isSuccess,
    isError,
  } = useQuery({
    queryKey: majorKeys.all,
    queryFn: async () => await MajorApi.getMajors(),
    enabled,
  })

  if (isLoading) {
    return {
      majors: [],
      isLoading: true,
      isSuccess: false,
    }
  }

  if (isError) {
    return {
      majors: [],
      isLoading: false,
      isSuccess: false,
    }
  }

  return {
    majors: majors?.data || [],
    isLoading: isLoading || isFetching,
    isSuccess,
  }
}

export default useMajors
