import React from 'react'
import { Control } from 'react-hook-form'
import SAPPFIlterButton from 'src/common/SAPPFIlterButton'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import { QUIZ_TYPE } from 'src/type/classes'
import { convertQuizType } from 'src/utils'
import { QUIZ_GRADING_METHOD } from 'src/constants'
import ListFilterLayout from 'src/components/layout/listFilter'

interface IProps {
  control: Control
  fetchTestQuiz: (page_index: number, page_size: number, params?: Object) => void
  loading: boolean
  handleReset: () => void
  filterTestQuiz: Object
}

const HeaderTestQuiz = ({
  control,
  fetchTestQuiz,
  loading,
  handleReset,
  filterTestQuiz,
}: IProps) => {
  const onSubmit = () => {
    fetchTestQuiz(1, 10, filterTestQuiz)
  }

  return (
    <React.Fragment>
      <div className={`d-flex justify-content-between align-items-center gap-5 flex-wrap}`}>
        <div className='card-title my-0 w-100'>
          <ListFilterLayout>
            <HookFormTextField
              control={control}
              name='quiz_name'
              placeholder='Search test/quiz'
              onSubmit={onSubmit}
              isListScreen
            />
            <HookFormSelectAntd
              control={control}
              name='quiz_type'
              classNameHeight='sapp-h-40'
              placeholder='Type'
              allowClear
              options={Object.keys(QUIZ_TYPE).map((key) => ({
                label: convertQuizType(key),
                value: QUIZ_TYPE[key as keyof typeof QUIZ_TYPE],
              }))}
            />

            <HookFormSelectAntd
              control={control}
              name='grading_method'
              classNameHeight='sapp-h-40'
              placeholder='Manual Grading?'
              allowClear
              options={QUIZ_GRADING_METHOD}
            />
          </ListFilterLayout>
        </div>
      </div>
      <div className='d-flex mt-4'>
        <SAPPFIlterButton
          titleReset='Reset'
          titleSubmit='Search'
          okClick={onSubmit}
          resetClick={handleReset}
          disabled={loading}
          loading={loading}
        />
      </div>
    </React.Fragment>
  )
}

export default HeaderTestQuiz
