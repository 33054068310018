import { Spin } from 'antd'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { GradeAPI } from 'src/apis/grade'
import { AlertCircleSharpIcon } from 'src/common/icons'
import { LANG_GRADE_PAGE } from 'src/constants/lang'
import { toast } from 'react-hot-toast'
import { GradeStatus, GradingStatus, IQuizAttemptInfo } from 'src/type/grade'

interface IFinishAlertModalProps {
  quizId: string
  setQuizAttemptInfo: Dispatch<SetStateAction<IQuizAttemptInfo>>
}

const FinishAlertModal = ({ quizId, setQuizAttemptInfo }: IFinishAlertModalProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [questions, setQuestions] = useState<number[]>([])
  const [isError, setIsError] = useState<boolean>(false)

  const closeModal = () => {
    setIsError(false)
    setIsOpen(false)
  }

  const finishGrading = async () => {
    setIsLoading(true)
    !isOpen && setIsOpen(true)
    await Promise.resolve(() => setTimeout(() => {}, 2000))
    const res = await GradeAPI.getQuizAttemptGradingQuestions(quizId, GradeStatus.UNGRADED)

    if (!res.success) {
      !isError && setIsError(true)
      return
    }

    if (res?.data?.length === 0) {
      // Finish grading
      try {
        const response = await GradeAPI.finishGrading(quizId)
        if (response.success) {
          setIsOpen(false)
          window.dispatchEvent(new CustomEvent('onFinishedGrading'))
          toast.success('Finish successfully!')
          // Update quiz status to FINISHED
          setQuizAttemptInfo((prev) => ({
            ...prev,
            status: GradingStatus.FINISHED,
          }))
        }
      } catch (error) {
        setIsError(true)
        toast.error('Failed to finish.')
      } finally {
        setTimeout(() => {
          setIsLoading(false)
        }, 1000)
      }
    } else {
      // Show popup
      const questions = res.data.map((question) => question.index)
      setIsOpen(true)
      setIsLoading(false)
      setQuestions(questions)
    }
  }

  useEffect(() => {
    window.addEventListener('onFinishGrading', finishGrading)

    return () => {
      window.removeEventListener('onFinishGrading', finishGrading)
    }
  }, [quizId])

  return (
    <Modal
      dialogClassName={`modal-dialog swal2-container finish-alert-model ${
        isLoading ? 'loading' : ''
      }`}
      centered
      show={isOpen && !isError}
    >
      {isLoading ? (
        <Spin size='large' />
      ) : (
        <>
          <div className='d-flex p-8 rounded-circle mx-auto icon-container'>
            <AlertCircleSharpIcon />
          </div>
          <div className='swal2-html-container text-center d-block alert-title'>
            <span className=''>
              {isError ? LANG_GRADE_PAGE.errorOcurred : LANG_GRADE_PAGE.cantFinish}
            </span>
          </div>
          {!isError && (
            <div className='swal2-html-container text-center d-block alert-desc'>
              <span className=''>
                {LANG_GRADE_PAGE.mustGradeQuestionsBefore}
                <span className='questions'>{questions.join(', ')}</span>
                {LANG_GRADE_PAGE.mustGradeQuestionsAfter}
              </span>
            </div>
          )}

          <div className='alert-actions'>
            <button
              className={`btn ${
                isError ? 'btn-close-modal' : 'btn-continue'
              } fw-medium btn-active-light-primary d-block w-100 rounded-0`}
              onClick={() => closeModal()}
            >
              {isError ? LANG_GRADE_PAGE.close : LANG_GRADE_PAGE.continueGrade}
            </button>
            {isError && (
              <button
                className='btn btn-retry fw-medium btn-active-light-primary d-block w-100 rounded-0'
                onClick={() => finishGrading()}
              >
                {LANG_GRADE_PAGE.tryAgain}
              </button>
            )}
          </div>
        </>
      )}
    </Modal>
  )
}

export default FinishAlertModal
