import React, { useEffect, useState } from 'react'
import { useConfirm } from 'src/hooks/use-confirm'
import HeaderTestQuiz from './HeaderTestQuiz'
import { useForm } from 'react-hook-form'
import TableTestQuiz from './TableTestQuiz'
import { ClassesApi } from 'src/apis/classes'
import { IClassQuiz } from 'src/type/classes'
import SappHeaderDetail from 'src/common/SappHeaderDetail'
import { useParams } from 'react-router-dom'

interface IProps {
  isDuration: boolean
  class_code: string | undefined
}

const ListTestQuiz = ({ isDuration, class_code }: IProps) => {
  const { contextHolder } = useConfirm()
  const [quizDetail, setQuizDetail] = useState<IClassQuiz>()
  const [loading, setLoading] = useState(false)
  const { id } = useParams()
  const { control, reset, watch } = useForm()

  const filterTestQuiz = {
    quiz_name: watch('quiz_name'),
    quiz_type: watch('quiz_type') || undefined,
    grading_method: watch('grading_method') || undefined,
  }

  async function fetchTestQuiz(page_index: number, page_size: number, params?: Object) {
    try {
      setLoading(true)
      const res = await ClassesApi.getTestQuiz(page_index ?? 1, page_size ?? 10, id, params)
      setQuizDetail(res?.data)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!id) return

    fetchTestQuiz(1, 10)
  }, [id])

  const handleReset = () => {
    reset()
    fetchTestQuiz(1, 10)
  }

  return (
    <React.Fragment>
      {contextHolder}
      <div className='card mb-5 mb-xl-10 flex'>
        <SappHeaderDetail title={`List Test/ Quiz (${quizDetail?.metadata?.total_records})`} />
        <div className='py-8 px-10'>
          <HeaderTestQuiz
            control={control}
            fetchTestQuiz={fetchTestQuiz}
            loading={loading}
            handleReset={handleReset}
            filterTestQuiz={filterTestQuiz}
          />
          <TableTestQuiz
            quizDetail={quizDetail}
            loading={loading}
            fetchTestQuiz={fetchTestQuiz}
            filterTestQuiz={filterTestQuiz}
            isDuration={isDuration}
            class_code={class_code}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export default ListTestQuiz
