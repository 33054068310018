import { Cell } from 'exceljs'

export const applyCellStyle = (cell: Cell, fillColor: string) => {
  cell.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: fillColor },
  }
  cell.font = {
    color: { argb: '000000' }, // Màu chữ đen
    bold: true,
  }
  cell.alignment = { vertical: 'middle', horizontal: 'center' }
  cell.border = {
    top: { style: 'thin', color: { argb: 'FF000000' } }, // Đường viền trên
    left: { style: 'thin', color: { argb: 'FF000000' } }, // Đường viền trái
    bottom: { style: 'thin', color: { argb: 'FF000000' } }, // Đường viền dưới
    right: { style: 'thin', color: { argb: 'FF000000' } }, // Đường viền phải
  }
}

export const columnToNumber = (col: string): number => {
  let number = 0
  for (let i = 0; i < col.length; i++) {
    number = number * 26 + (col.charCodeAt(i) - 'A'.charCodeAt(0) + 1)
  }
  return number
}

const numberToColumn = (num: number): string => {
  let col = ''
  while (num > 0) {
    const remainder = (num - 1) % 26
    col = String.fromCharCode('A'.charCodeAt(0) + remainder) + col
    num = Math.floor((num - 1) / 26)
  }
  return col
}

export const getRangeOfCells = (start: string, end: string, index: string): string[] => {
  const startCode = columnToNumber(start) // Chuyển cột bắt đầu sang số
  const endCode = columnToNumber(end) // Chuyển cột kết thúc sang số

  if (endCode < startCode) {
    console.error('End column is smaller than start column!')
    return []
  }

  const range: string[] = []
  for (let i = startCode; i <= endCode; i++) {
    range.push(numberToColumn(i) + index) // Chuyển ngược từ số sang cột chữ
  }
  return range
}

export const convertChar = (columnName: string, carry: number): string => {
  let result = ''
  for (let i = columnName.length - 1; i >= 0; i--) {
    const charCode = columnName.charCodeAt(i) - 65 // Chuyển ký tự về chỉ số (0-25)
    const nextCharCode = (charCode + carry) % 26 // Tính ký tự tiếp theo
    carry = Math.floor((charCode + carry) / 26) // Xác định xem có cần "nhảy" không
    result = String.fromCharCode(nextCharCode + 65) + result // Thêm ký tự vào kết quả
  }
  if (carry > 0) {
    result = 'A' + result
  }
  return result
}
